<template>
  <div class="home_" v-if="isinit">
    <div class="banner">
      <div class="main">
        <img src="../assets/img/logo2022.png" alt="" />
        <h1>全球互联网架构大会<p>GLOBAL INTERNET ARCHITECTURE CONFERENCE</p></h1>
        <p>
          <span>连接未来的技术架构</span>
        </p>
        <div class="time">
          <p>
            <!-- <i class="glyphicon glyphicon-time"></i> -->
            <img src="../assets/img/shijian.png" alt="" /> {{ time }}
          </p>
          <p>
            <!-- <i class="glyphicon glyphicon-map-marker"></i> -->
            <img src="../assets/img/didian.png" alt="" />{{ place }}
          </p>
        </div>
        <div class="btns">
          <!-- <p class="act" @click="toOrder()">GIAC 上海站报名中</p> -->
          <p class="act">GIAC 上海站</p>
          <p @click="toNext('/2022sz/home')">GIAC 深圳站</p>
        </div>
      </div>
    </div>

    <div class="giac-container top">
      <div class="main">
        <div class="desc">
          <div class="le">
            <h2>会议介绍</h2>
            <p>
              全球互联网架构大会(简称“GIAC”)是长期关注互联网技术与架构的高可用架构技术社区和msup推出的面向架构师、技术负责人及高端技术从业人员的年度技术架构大会，是中国地区规模最大的技术会议之一。
            </p>
          </div>
          <div class="ri">
            <div class="titem">
              <h1>24</h1>
              <p class="pc">分会场主题<br />覆盖主流架构话题</p>
              <p class="mob">
                <strong>分会场主题</strong><br />覆盖主流架构话题
              </p>
            </div>
            <div class="titem">
              <h1>84</h1>
              <p class="pc">热门案例<br />深度解密架构演进</p>
              <p class="mob"><strong>热门案例</strong><br />深度解密架构演进</p>
            </div>
            <div class="titem">
              <h1>1000+</h1>
              <p class="pc">架构师<br />共同学习共同进步</p>
              <p class="mob"><strong>架构师</strong><br />共同学习共同进步</p>
            </div>
          </div>
        </div>
        <p class="mob">
          <span>全球互联网架构大会(简称“GIAC”)</span
          >是长期关注互联网技术与架构的高可用架构技术社区和msup推出的面向架构师、技术负责人及高端技术从业人员的年度技术架构大会，是中国地区规模最大的技术会议之一。
        </p>
        <Speakers :_speakers="speakers"></Speakers>
        <!--  v-if="checkBoolean(speaker)" -->
        <p class="more pc">更多大咖正在邀请确认中……</p>
      </div>
      <div class="hshadow"></div>
    </div>

    <div class="theme-setting">
      <div class="giac-container">
        <div class="main">
          <h1>GIAC议题设置</h1>
          <p>
            本届GIAC议题共设置有24个主题，覆盖各类架构热点领域，每个主题由业内知名架构师、技术负责人等专家担任出品人，负责议题选取和质量把控。
          </p>
          <div class="pc">
            <div class="list">
              <div
                class="litem"
                :class="theme == index ? 'act' : ''"
                v-for="(item, index) in themeList"
                :key="'home_theme_' + index"
                @click="setTheme($event,index,'pc')"
              >
                <slot v-for="(titem, tindex) in item">
                  {{ tindex > 0 ? "/" : "" }}{{ titem.venueName }}
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="giac-container pc">
        <div class="main">
          <div
            class="intro"
            v-for="(item, index) in themeList[theme]"
            :key="'home_intro_' + index"
          >
            <div class="left">
              <h1>{{ item.venueName }}</h1>
              <p>出品人：{{ item.name }}</p>
            </div>
            <div class="right">
              <div
                class="course"
                v-for="(titem, cindex) in item.times.split(',')"
                :key="'home_intro_course_' + cindex"
                style="width: 25%;"
              >
                <!-- :style="`width:${100 / item.times.split(',').length}%;`" -->
                <!-- {{titem.split('-')[0]}} {{titem.split('-')[1]}} -->
                <slot v-for="(citem, cindex) in item.schedulingCourse">
                  <div
                    class="box"
                    v-if="citem && citem.startTime == titem.split('-')[0]"
                    :key="'schedulingCourse_' + index + '_' + cindex"
                  >
                    <h2 @click="toNext('/2022sz/course',{id:citem.courseid})">
                      《{{ citem.courseTitle }}》
                    </h2>
                    <p @click="toNext('/2022sz/course',{id:citem.courseid})">
                      {{ citem.name }} {{ citem.company }} {{ citem.position }}
                    </p>
                  </div>
                </slot>
                <div class="box none">
                  <h1>敬请期待</h1>
                </div>
              </div>
              <div class="course" v-if="item.times.split(',').length<4" style="width: 25%;">
                <div class="box"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="giac-container mob" id="home_themes" v-if="!ispc">
        <!-- collapse -->
        <div
          class="theme"
          :class="['theme_' + index, theme == index ? 'act' : '']"
          v-for="(item, index) in themeList"
          :key="'home_theme_mob_' + index"
        >
          <!-- head -->
          <div class="theme_head" 
              :class="theme == index ? 'act' : ''"
              :id="'header_'+index" 
              @click.prevent="setTheme($event,index,'mob')"
              :data-target="'#theme_list_' + index" 
              data-toggle="collapse"
              aria-expanded="false"
              :aria-controls="'theme_list_' + index"
          >
            <slot v-for="(titem, tindex) in item">
              {{ tindex > 0 ? "/" : "" }}{{ titem.venueName }}
            </slot>
            <span
              :class="theme == index ? 'open' : 'close'"
            ></span>
          </div>
          <!-- body -->
          <div class="collapse" :id="'theme_list_' + index" data-parent="#home_themes" :aria-labelledby="'header_'+index">
            <div
              class="theme_detail"
              v-for="(titem, tindex) in item"
              :key="'home_theme_mob_' + index + '_' + tindex"
            >
              <!-- title -->
              <div class="theme_title"
                  :id="'header_'+index+'_'+tindex" 
                  :data-target="'#theme_list_' + index + '_' + tindex" 
                  data-toggle="collapse"
                  aria-expanded="false"
                  :aria-controls="'theme_list_' + index + '_' + tindex"
              >
                {{ titem.venueName }}
              </div>
              <!-- list -->
              <div class="collapse" :id="'theme_list_' + index + '_' + tindex" :data-parent="'#theme_list_' + index" :aria-labelledby="'header_'+index+'_'+tindex">
                <p v-if="!titem.schedulingCourse||titem.schedulingCourse.length==0" class="no_course">主题下暂无课程</p>
                <slot v-else>
                  <div class="theme_course"
                      v-for="(citem,cindex) in titem.schedulingCourse"
                      :key="'theme_course_' + index + '_' + tindex + '_' + cindex"
                      @click="toNext('/2022sz/course',{id:citem.courseid})"
                  >
                    <h3>{{citem.courseTitle}}</h3>
                    <p>{{citem.name}} {{citem.company}} {{citem.position}}</p>
                  </div>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="giac-container cases">
      <div class="main">
        <h1>本期精选案例</h1>
        <!-- <p>全球互联网架构大会(简称“GIAC”)</p> -->
        <selected-case :caseList="caseList"></selected-case>
        <div class="case-wrapper" v-if="caseList.length > 0">
          <router-link class="all-case" to="/case">查看全部案例</router-link>
        </div>
      </div>
    </div>

    <div class="giac-container comment-content">
      <img
        class="pc"
        src="../assets/img/home-comment-bg-2022.png"
        width="100%"
      />
      <img class="mob" src="../assets/img/mob-comment-bg.png" width="100%" />
      <div class="main">
        <h1>参会学员收获</h1>
        <Swiper :textList="commentList"></Swiper>
      </div>
    </div>

    <div class="giac-container conferee">
      <div class="main">
        <h1>联合主办方</h1>
        <!-- <p>全球互联网架构大会(简称“GIAC”)</p> -->
        <div class="pc" style="padding-bottom:50px;">
          <div class="companys" v-if="sponsorimg!=''">
            <div class="list pc">
              <div class="line" style="padding-left:0;justify-content:center;">
                <div class="litem" :style="'width:'+100/confereeItem+'%;'">
                  <div class="box">
                    <img :src="sponsorimg" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mob" style="padding-bottom:35px;">
          <div class="companys" v-if="sponsorimg!=''">
            <div class="citem">
              <div class="box">
                <img :src="sponsorimg" alt="">
              </div>
            </div>
          </div>
        </div>
        <h1>历史参会企业</h1>
        <!-- <p>全球互联网架构大会(简称“GIAC”)</p> -->
        <div class="pc">
          <div class="companys">
            <div class="left"></div>
            <div class="right"></div>
            <div class="list pc" v-if="conferee">
              <div
                class="line"
                :class="confereeList.length < confereeItem ? 'one' : ''"
                v-for="num in Math.ceil(confereeList.length / confereeItem)"
                :key="'home_conferee_line_' + num + '_' + confereeItem"
              >
                <div
                  class="litem"
                  :style="'width:' + 100 / confereeItem + '%;'"
                  v-for="(item, index) in confereeList.slice(
                    (num - 1) * confereeItem,
                    num * confereeItem
                  )"
                  :key="'home_conferee_item_' + num + '_' + index"
                >
                  <div class="box">
                    <img v-lazy="item.file" alt="" @click="toNew(item.content)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mob">
          <div class="companys">
            <div class="citem"
                v-for="(item, index) in confereeList"
                :key="'home_conferee_item_mob_' + index"
            >
              <div class="box">
                <img v-lazy="item.file" alt="" @click="toNew(item.content)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="giac-container sponsor">
      <div class="main">
        <sponsor-list :_from="'source_home'"></sponsor-list>
      </div>
    </div>

    <div class="giac-container invite">
      <div class="pc">
        <div class="main">
          <img src="../assets/img/invite.png" alt="" />
          <p>第{{session}}届2022年GIAC全球互联网架构大会邀请函</p>
          <div class="link">
            <a v-if="global.year==theyear" @click="toOrder">
              <p>立即报名</p>
            </a>
            <a v-else>
              <p>已售罄</p>
            </a>
            <a href="http://p.msup.cn/Y9Kx" target="_blank">
              <p>案例征集</p>
            </a>
          </div>
        </div>
      </div>
      <div class="mob">
        <div class="main">
          <div class="left">
            <img src="../assets/img/invite.png" alt="" />
            <p>第九届2022年GIAC全球互联网架构大会邀请函</p>
          </div>
          <div class="link">
            <a v-if="global.year==theyear" @click="toOrder">
              <p>立即报名</p>
            </a>
            <a v-else>
              <p>已售罄</p>
            </a>
            <a href="http://p.msup.cn/jTH0" target="_blank">
              <p>案例征集</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  id_arr,
  sid,
  session,
  sponsorimg,
  getMeetingTime,
  getSueLecturer,
  sidlecturer1,
  sidlecturer2,
  sidcase,
  getAllCourse,
  getSue,
  sidsponsorhistory,
  sidsponsor,
  getThemeInfo,
  getUserInfo
} from "@/utils/api.js";

export default {
  name: "Home_",
  components: {
    Speakers: () => import("@/components/Speakers.vue"),
    SelectedCase: () => import("@/components/SelectedCaseNew.vue"),
    Swiper: () => import("@/components/Swiper.vue"),
    SponsorList: () => import("@/components/SponsorList.vue")
  },
  data() {
    let _this = this;
    return {
      theyear: Object.keys(id_arr).sort((a,b)=>{return b.localeCompare(a)})[0],
      session: session(_this.global.year),
      sponsorimg: sponsorimg(_this.global.year),
      isinit: true,
      ispc: true,
      swidth: window.innerWidth,
      place: "",
      time: "",
      speaker: null,
      speakers: {},
      theme_cli_pc: false,
      theme_cli_mob: false,
      theme: 0,
      themeList: [],
      caseList: [],
      confereeList: [],
      confereeItem: 7,
      conferee: false,
      commentList: [
        "每年参加GIAC是为了看看业界领先的公司都在做些什么，有一些前沿的技术是怎么落地在别人的业务上，这对我们调研新技术、应用新技术都有很多帮助。 -来自华为技术有限公司",
        "参加GIAC，可以现场聆听技术圈的一些大牛的分享，了解大牛们是怎么思考和解决问题的，对拓宽视野，不在研发上走弯路很有用。 -来自平安科技（深圳）有限公司",
        "每年参加GIAC一般都是组团来，每个技术线都会来一些同学，主要是学习一些落地实践，大家都学习进步都快一些，每次会后也组织内部分享，是很好地学习方式。 -来自顺丰科技有限公司"
      ]
    };
  },
  created() {
    this.setInit();
  },
  watch: {
    $route(to, from) {
      if(to.name != from.name) {
        this.setInit();
      }
    }
  },
  methods: {
    setInit: function() {
      this.isinit = false;
      this.initsize();
      this.getPlaceTime();
      this.getChairman(); // 联席主席
      this.getSpeakers(); // 出品人
      this.getTheme();
      this.getCases();
      this.getConferee(); // 历史参会企业
      this.$forceUpdate();
      this.isinit = true;
    },

    initsize: function() {
      this.swidth = window.innerWidth;
      if (this.swidth > 767) {
        this.ispc = true;
        this.theme = 0;
      } else {
        this.ispc = false;
        this.theme = -1;
      }
      // console.log(this.ispc, this.theme);
      this.setresize();
    },
    setresize: function() {
      // this.swidth = window.innerWidth;
      window.addEventListener("resize", () => {
        let swidth = window.innerWidth;
        // pc->mob || mob->mob
        if (
          (this.swidth > 767 && swidth <= 767) ||
          (this.swidth <= 767 && swidth <= 767)
        ) {
          this.ispc = false;
          this.theme_cli_pc = false;
          !this.theme_cli_mob ? this.theme=-1 : "";
        }
        // mob->pc || pc->pc
        if (
          (this.swidth <= 767 && swidth > 767) ||
          (this.swidth > 767 && swidth > 767)
        ) {
          this.ispc = true;
          this.theme_cli_mob = false;
          !this.theme_cli_pc ? this.theme=0 : "";
        }
        // console.log(this.swidth, swidth, this.ispc, this.theme, this.theme_cli_pc, this.theme_cli_mob);
        this.conferee = false;
        if (swidth >= 1200) {
          this.confereeItem = 7;
        } else if (swidth >= 1000) {
          this.confereeItem = 6;
        } else {
          this.confereeItem = 5;
        }
        this.conferee = true;
        this.$forceUpdate();
      });
    },

    setTheme: function(e,index,type) {
      // e.stopPropagation();
      if(type == "pc"){
        this.theme = index;
        this.theme_cli_pc = true;
        this.theme_cli_mob = false;
      } else if(type == "mob"){
        this.theme_cli_pc = false;
        if(this.theme == index){
          this.theme = -1;
          this.theme_cli_mob = false;
        } else {
          this.theme = index;
          this.theme_cli_mob = true;
        }
      }
      // console.log('cli ', this.theme, this.theme_cli_pc, this.theme_cli_mob);
    },

    // 暂未用
    checkBoolean: function(_data) {
      let res = false;
      let data = [];
      // Object || Array   ||   Number || Boolean || null || undefined
      if (typeof _data == "object") {
        data =
          _data.toString() == "[object Object]"
            ? [...Object.values(_data)]
            : _data;
      } else {
        data[0] = _data;
      }
      for (let i of data) {
        console.log(i);
        !i || i == 0 || i == undefined || i == null
          ? (res = false)
          : (res = true);
        if (res == false) break;
      }
      return res;
    },

    // 获取数据
    getPlaceTime: function() {
      this.$http
        .jsonp(getMeetingTime + sid(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(res => {
          // console.log(res);
          if (res.status === "200") {
            // const start = this.formatTime(res.data.startTime, "start");
            // const end = this.formatTime(res.data.endTime, "end");
            const start = this.global.formatTime(res.data.startTime, "Y.M.D");
            const end = this.global.formatTime(res.data.endTime, "D");
            this.place = `${res.data.address}`;
            this.time = `${start}-${end}`;
          }
        });
    },
    getChairman: function() {
      this.speakers.chairman = {
        title: "联席主席",
        desc: "带领技术团队走向未来的变革者",
        list: []
      };
      // this.speaker = {};
      // this.speaker.chairman = false;
      this.$http
        .jsonp(getSueLecturer + sidlecturer1(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(res => {
          // console.log(res);
          if (res.errno == "0") {
            this.speakers.chairman.list = res.data[sidlecturer1(this.global.year)]
              ? res.data[sidlecturer1(this.global.year)]
              : [];
            // this.speaker.chairman = true;
            this.$forceUpdate();
          }
        });
    },
    getSpeakers: function() {
      this.speakers.speaker = {
        title: "出品人",
        desc: "带领技术团队走向未来的变革者",
        list: []
      };
      // this.speaker.speaker = false;
      this.$http
        .jsonp(getSueLecturer + sidlecturer2(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(res => {
          // console.log("268 》》》 ",res);
          if (res.errno == 0) {
            this.speakers.speaker.list = res.data[sidlecturer2(this.global.year)]
              ? res.data[sidlecturer2(this.global.year)]
              : [];
            // this.speaker.speaker = true;
            this.$forceUpdate();
          } else {
            this.speakers.speaker.list = [];
          }
        });
    },
    getTheme: function() {
      this.$http
        .jsonp(getThemeInfo + sid(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(res => {
          // console.log(res)
          if (res.errno == 0) {
            this.themeList = this.splitTheme(res.data, 4);
            // console.log(this.themeList);
          }
        });
    },
    splitTheme(themeList, num) {
      const list = [];
      for (let i = 0; i < themeList.length; ) {
        list.push(themeList.slice(i, (i += num)));
      }
      return list;
    },
    getSelectedCase() {
      this.$http
        .jsonp(getSueLecturer + sidcase(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(res => {
          if (res.errno === "0") {
            let idList = res.data[sidcase(this.global.year)].map(item => item.content);
            this.getCase(idList);
          }
        });
    },
    getCases: function(_idList) {
      this.caseList = [];
      let idList = _idList;
      this.$http
        .jsonp(getAllCourse + sid(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(res => {
          // console.log(res);
          if (res.errno === "0") {
            // this.caseList = res.data;
            idList && idList.length > 0
              ? res.data.map(item => {
                  if (idList.indexOf(item.courseid) >= 0) {
                    this.caseList.push(item);
                  }
                })
              : "";
          }
        });
    },
    getConferee() {
      this.$http
        .jsonp(getSue + sidsponsorhistory(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(res => {
          // console.log(res);
          if (res.errno == "0") {
            // 历史参会企业
            this.confereeList = [...res.data[0]];
            this.conferee = true;
            // this.confereeList.push(...res.data[0]); this.confereeList.push(...res.data[0]); this.confereeList.push(...res.data[0]); this.confereeList.push(...res.data[0]);
            // console.log(this.confereeList);
          }
        });
    },

    toOrder: function() {
      const token = localStorage.getItem("token");
      console.log(token);
      if (token && token !== null) {
        let url = `${getUserInfo}?token=${token}`;
        this.$http
          .jsonp(url)
          .then(data => {
            return data.json();
          })
          .then(res => {
            console.log("home- checkUserInfo", res);
            if (res.status === "200") {
              let flag = true;
              for (const key in res.data) {
                if (res.data[key] === "") {
                  flag = false;
                  if (window.confirm("购票需要您先完善个人信息")) {
                    this.$router.push({
                      path: "/2022sh/userCenter",
                      query: { active: "5" }
                    });
                  }
                  break;
                }
              }
              if (flag) {
                this.global.hashParam.qd
                ? this.$router.push({
                    path: `/2022sh/order`,
                    query: {qd: this.global.hashParam.qd}
                  })
                : this.$router.push({
                    path: `/2022sh/order`,
                  });
              }
            } else if (res.code === "1001" || res.status === "1011") {
              this.$message({
                message: "当前登录状态已过期，请重新登录",
                type: "warning"
              });
              window.localStorage.removeItem("logins");
              window.localStorage.removeItem("token");
              let order = `/2022sh/order`+`${this.global.hashParam.qd?'?qd='+this.global.hashParam.qd:''}`;
              window.localStorage.setItem("linkurl", order);
              setTimeout(() => {
                this.$router.push({
                  path: "/2022sh/login"
                });
              }, 2000);
            } else {
              console.log("获取用户信息失败");
            }
          });
      } else {
        this.$message({
          message: "当前登录状态已过期，请重新登录",
          type: "warning"
        });
        window.localStorage.removeItem("logins");
        window.localStorage.removeItem("token");
        let order = `/2022sh/order`+`${this.global.hashParam.qd?'?qd='+this.global.hashParam.qd:''}`;
        window.localStorage.setItem("linkurl", order);
        setTimeout(() => {
          this.$router.push({
            path: "/2022sh/login"
          });
        }, 2000);
      }
    },
    toNext: function(path, query) {
      this.$router.push({
        path: path,
        query: query
      });
    },
    toNew: function(url) {
      if(url && url!=""){
        window.open(url,"_blank");
      }
    },
  }
};
</script>

<style lang="scss">
.home_ {
  width: 100%;
  background-color: #ffffff;
  text-align: left;
  a:hover,
  a:visited,
  a:focus {
    color: inherit;
    text-decoration: none;
  }
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
  }
  .banner {
    width: 100%;
    height: 777px;
    padding: 130px 0 0 0;
    // height: 685px;
    // padding: 135px 0 0 0;
    background-image: url("../assets/img/giac2022_banner_777.png");
    background-repeat: no-repeat;
    background-position: right;
    // background-size: auto 100%;
    background-size: cover;
    position: relative;
    .main {
      width: 100%;
      max-width: 1240px;
      margin: 0 auto;
      padding: 0 20px;
      h1 {
        width: fit-content;
        min-height: 80px;
        line-height: 80px;
        font-size: 80px;
        font-family: Source Han Sans CN Heavy;
        font-weight: 800;
        color: #ffffff;
        margin-top: 35px;
        margin-bottom: 0;
        p {
          width: 100%;
          min-height: 20px;
          line-height: 20px;
          margin-top: 25px;
          margin-bottom: 0;
          font-size: 20px;
          font-family: Arial;
          font-weight: bold;
          color: #ffffff;
          letter-spacing: 5px;
          text-align-last: justify;
        }
      }
      > p {
        min-height: 20px;
        line-height: 20px;
        margin-top: 50px;
        margin-bottom: 0;
        font-size: 20px;
        font-family: Arial;
        font-weight: bold;
        color: #ffffff;
        span {
          height: 67px;
          line-height: 67px;
          font-size: 40px;
          font-family: Alibaba PuHuiTi Medium;
          font-weight: 500;
          color: #ffffff;
          padding-right: 27px;
          background: linear-gradient(
            -90deg,
            rgba(78, 168, 151, 0.96) 10%,
            rgba(72, 73, 223, 0.72) 65%,
            rgba(69, 74, 206, 0.59) 100%
          );
          border-radius: 34px;
          display: inline-block;
        }
      }
      div.time {
        margin-top: 45px;
        display: flex;
        flex-wrap: nowrap;
        p {
          min-height: 25px;
          line-height: 25px;
          font-size: 25px;
          font-family: Alibaba PuHuiTi Light;
          font-weight: bold;
          color: #ffffff;
          margin-right: 40px;
          margin-bottom: 0;
          img {
            width: auto;
            height: 30px;
            margin-right: 15px;
          }
        }
      }
      div.btns {
        margin-top: 40px;
        display: flex;
        flex-wrap: nowrap;
        p {
          width: 264px;
          line-height: 66px;
          margin-right: 30px;
          font-size: 20px;
          font-family: Source Han Sans CN Regular;
          font-weight: 400;
          text-align: center;
          color: #FFFFFF;
          background: linear-gradient(0deg, #0029BC 0%, #495FFF 100%);
          border-radius: 8px;
          cursor: pointer;
        }
        p.act {
          color: #3942B7;
          background: #FFFFFF;
        }
      }
    }
  }
  .giac-container {
    width: 100%;
    .main {
      width: 100%;
      max-width: 1400px;
      padding: 0 25px;
      margin: 0 auto;
      > h1,
      h1.head_title,
      .source_home .title {
        width: 100%;
        // height: 32px;
        line-height: 32px;
        font-size: 32px;
        font-family: PingFang SC Bold;
        font-weight: bold;
        color: #00081a;
        text-align: center;
      }
      > p,
      p.head_title,
      .source_home .desc {
        width: 100%;
        max-width: 757px;
        margin: 0 auto;
        margin-top: 15px;
        line-height: 28px;
        font-size: 19px;
        font-family: PingFang SC Regular;
        font-weight: 400;
        color: #777777;
        text-align: center;
      }
    }
    p.more {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      font-size: 24px;
      font-family: PingFang SC Medium;
      font-weight: 500;
      color: #2630b2;
      text-align: center;
      background: linear-gradient(0deg, #5da5ff 0%, #2b228d 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .top {
    padding-bottom: 75px;
    top: -63px;
    position: relative;
    background-color: transparent;
    .main {
      position: relative;
      z-index: 2;
      .desc {
        width: 100%;
        max-width: 1330px;
        padding: 50px 60px;
        margin: 0 auto;
        background-color: #ffffff;
        box-shadow: 0px 0px 14px 0px rgba(110, 110, 110, 0.13);
        border-radius: 12px;
        display: flex;
        flex-wrap: nowrap;
        .le {
          width: 100%;
          padding-right: 50px;
          h2 {
            height: 22px;
            line-height: 22px;
            font-size: 22px;
            font-family: PingFang SC Bold;
            font-weight: bold;
            color: #2630b2;
          }
          p {
            line-height: 25px;
            font-size: 14px;
            font-family: PingFang SC Medium;
            font-weight: 500;
            color: #666666;
            margin-top: 10px;
          }
        }
        .ri {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-evenly;
          border-left: 1px solid #d1d1d1;
          .titem {
            h1 {
              width: fit-content;
              height: 55px;
              line-height: 30px;
              font-size: 42px;
              font-family: Arial;
              font-weight: 400;
              color: #444444;
              display: grid;
            }
            h1::after {
              content: "";
              display: inline-block;
              width: 40px;
              height: 3px;
              margin-top: 20px;
              background-color: #2630b2;
            }
            p {
              width: fit-content;
              line-height: 21px;
              font-size: 15px;
              font-family: Alibaba PuHuiTi;
              font-weight: 400;
              color: #666666;
              margin-top: 10px;
            }
          }
        }
      }
      .more {
        padding-top: 20px;
        height: 44px;
        line-height: 24px;
      }
    }
    .hshadow {
      width: 100%;
      height: 467px;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      background: linear-gradient(0deg, #f6f8fe 1%, #ffffff 53%);
    }
  }
  .theme-setting {
    .giac-container {
      .main {
        // max-width: 1250px;
        div.list {
          display: flex;
          flex-wrap: nowrap;
          margin-top: 65px;
          margin-right: -35px;
          .litem {
            width: 100%;
            margin-right: 35px;
            line-height: 25px;
            font-size: 17px;
            font-family: PingFang SC Regular;
            font-weight: 400;
            color: #999999;
            text-align: center;
            border-bottom: 5px solid transparent;
            cursor: pointer;
          }
          .litem.act {
            color: #2630b2;
            border-color: #2630b2;
            background: linear-gradient(
              0deg,
              #f4f6fd 0%,
              #ffffff 57.99999999999999%
            );
          }
        }
        div.intro {
          width: 100%;
          height: 100%;
          // display: flex;
          // flex-wrap: nowrap;
          display: table;
          margin-bottom: 18px;
          background-color: #ffffff;
          > div {
            width: 100%;
          }
          .left {
            width: 252px;
            min-width: 252px;
            max-width: 252px;
            padding: 55px 0;
            display: table-cell;
            vertical-align: middle;
            background-color: #f3f5fb;
            h1,
            p {
              width: 100%;
              text-align: center;
            }
            h1 {
              line-height: 22px;
              font-size: 22px;
              font-family: PingFang SC Medium;
              font-weight: 500;
              color: #00081a;
            }
            p {
              margin-top: 20px;
              line-height: 18px;
              font-size: 18px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #666666;
            }
          }
          .right {
            // display: flex;
            // flex-wrap: nowrap;
            // width: fit-content;
            width: 100%;
            height: 100%;
            display: table;
            vertical-align: middle;
            padding: 30px 0;
            .course {
              height: 100%;
              display: table-cell;
              vertical-align: middle;
              // display: table;
              position: relative;
              .box.none {
                top: 0;
                left: 0;
                position: absolute;
                vertical-align: middle;
                text-align: center;
                z-index: 1;
                h1 {
                  top: 50%;
                  left: 0;
                  // left: 50%;
                  margin-top: -11px;
                  // margin-left: -115px;
                  position: absolute;
                }
              }
              .box {
                width: 100%;
                height: 100%;
                padding: 0 10px;
                position: relative;
                z-index: 2;
                background-color: #ffffff;
                h1,
                h2,
                p {
                  margin: 0 auto;
                }
                h2,
                p {
                  max-width: 230px;
                  cursor: pointer;
                }
                h1 {
                  width: 100%;
                  line-height: 22px;
                  font-size: 22px;
                  font-family: PingFang SC Medium;
                  font-weight: 500;
                  color: #3b44b9;
                  text-align: center;
                }
                h2 {
                  // height: 41px;
                  line-height: 25px;
                  font-size: 17px;
                  font-family: PingFang SC Medium;
                  font-weight: 500;
                  color: #3b44b9;
                }
                p {
                  margin-top: 10px;
                  line-height: 25px;
                  font-size: 15px;
                  font-family: PingFang SC Regular;
                  font-weight: 500;
                  color: #999999;
                }
              }
            }
            .course:not(:nth-of-type(1)) {
              .box {
                border-left: 1px solid #cccccc;
              }
            }
          }
        }
      }
    }
    .giac-container:nth-of-type(1) {
      overflow: hidden;
      background-color: #ffffff;
      .main {
        margin-top: 14px; // 77-63
      }
    }
    .giac-container:nth-of-type(2) {
      background-color: #f7f9fe;
      padding: 47px 0 70px 0;
    }
  }
  .cases {
    .main {
      max-width: 1280px;
      margin-top: 65px;
      padding: 0;
      padding-bottom: 77px;
      > p {
        margin-bottom: 50px;
      }
      .case-wrapper {
        padding-top: 20px;
        text-align: center;
        .all-case {
          width: 240px;
          height: 60px;
          line-height: 60px;
          font-size: 20px;
          font-family: Source Han Sans CN Regular;
          font-weight: 400;
          color: #ffffff;
          display: inline-block;
          border-radius: 8px;
          background: linear-gradient(0deg, #0029bc 0%, #495fff 100%);
        }
      }
    }
  }
  .comment-content {
    min-height: 440px;
    position: relative;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      top: 0;
      left: 0;
      z-index: 1;
      position: absolute;
    }
    .main {
      padding-top: 80px;
      z-index: 2;
      position: relative;
      > h1 {
        margin-bottom: 45px;
        color: #ffffff;
      }
    }
  }
  .conferee {
    background-color: #f7f9fe;
    .main {
      max-width: 1480px;
      padding-top: 75px;
      padding-bottom: 50px;
      .pc, .mob {
        width: 100%;
      }
      .pc {
        .companys {
          width: 100%;
          margin-top: 70px;
          position: relative;
          .left,
          .right {
            width: 120px;
            height: 100%;
            top: 0;
            z-index: 2;
            position: absolute;
          }
          .left {
            left: 0;
            background: linear-gradient(90deg, #f7f9fe 0%, transparent 100%);
          }
          .right {
            right: 0;
            background: linear-gradient(-90deg, #f7f9fe 0%, transparent 100%);
          }
          .list {
            width: 100%;
            z-index: 1;
            position: relative;
            .line:nth-of-type(2n + 1) {
              padding-left: 30px;
            }
            .line:nth-of-type(2n) {
              padding-right: 30px;
            }
            .line.one {
              justify-content: center;
            }
            .line {
              width: 100%;
              height: 80px;
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 45px;
              .litem {
                // width: 100/7; // 14.2857%
                // height: 100%;
                // min-height: 80px;
                height: 80px;
                padding: 0 10px;
                display: table;
                overflow: hidden;
                .box {
                  width: 100%;
                  // height: 100%;
                  display: table-cell;
                  vertical-align: middle;
                  background-color: #ffffff;
                  box-shadow: 0px 0px 14px 0px rgba(43, 66, 131, 0.07);
                  border-radius: 8px;
                  overflow: hidden;
                  img {
                    width: 100%;
                    // height: 100%;
                    object-fit: contain;
                    object-position: center;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
      .mob {
        .companys {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          // justify-content: space-between;
          .citem {
            // width: 155px;
            // height: 69px;
            width: 23.4%;
            min-width: 150px;
            height: 55px;
            margin: 0 5px;
            margin-top: 15px;
            background: #FFFFFF;
            box-shadow: 0px 0px 14px 0px rgba(43, 66, 131, 0.07);
            border-radius: 8px;
            overflow: hidden;
            .box {
              width: 100%;
              text-align: center;
              img {
                width: auto;
                max-width: 100%;
                height: 55px;
                object-fit: contain;
                object-position: center;
              }
            }
          }
        }
      }
    }
  }
  .sponsor {
    .main {
      .source_home {
        .sponsor .sponsor-option p.title {
          padding: 75px 0;
        }
      }
    }
  }
  .invite {
    background: linear-gradient(0deg, #3333c8 0%, #5657ee 100%);
    .pc, .mob {
      width: 100%;
    }
    .pc {
      .main {
        max-width: 1135px;
        padding-top: 32px;
        padding-bottom: 32px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        > img {
          width: auto;
        }
        > p {
          max-width: 100%;
          margin-top: 0;
          line-height: 55px;
          font-size: 22px;
          font-family: PingFang SC Medium;
          font-weight: 500;
          color: #ffffff;
          opacity: 0.8;
        }
        .link {
          display: flex;
          flex-wrap: nowrap;
          a p {
            width: 162px;
            height: 40px;
            line-height: 40px;
            font-size: 18px;
            font-family: PingFang SC Bold;
            font-weight: bold;
            text-align: center;
            cursor: pointer;
          }
          a:nth-of-type(1) p {
            color: #2630b2;
            background: #ffffff;
            border-radius: 5px 5px 5px 5px;
          }
          a:nth-of-type(2) p {
            margin-left: 13px;
            color: #ffffff;
            background: #1c1788;
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 5px 5px 5px 5px;
          }
        }
      }
    }
    .mob {
      .main {
        display: flex;
        flex-wrap: nowrap;
        padding-top: 15px;
        padding-bottom: 15px;
        .left {
          width: 100%;
          img {
            height: 30px;
          }
          p {
            margin-top: 5px;
            margin-bottom: 0;
            line-height: 20px;
            font-size: 14px;
            font-family: PingFang SC Bold;
            font-weight: bold;
            color: #FFFFFF;
          }
        }
        .link {
          width: 175px;
          min-width: 175px;
          max-width: 175px;
          padding-left: 20px;
          a {
            p {
              width: 100%;
              height: 30px;
              margin-bottom: 0;
              line-height: 28px;
              font-size: 14px;
              font-family: PingFang SC Bold;
              font-weight: bold;
              text-align: center;
              border: 1px solid #FFFFFF;
              border-radius: 6px;
            }
          }
          a:nth-of-type(1) {
            p {
              margin-bottom: 8px;
              color: #2630B2;
              background: #FFFFFF;
            }
          }
          a:nth-of-type(2) {
            p {
              color: #FFFFFF;
              background: #1C1788;
            }
          }
        }
      }
    }
  }
}

.pc {
  display: block;
}
.mob {
  display: none;
}

@media (max-width:1024px) and (min-width: 767px) {
  .home_ .theme-setting .giac-container .main div.intro .left {
    width: 170px;
    min-width: 170px;
    max-width: 170px;
  }
}
@media (max-width:915px) and (min-width: 767px) {
  .home_ .invite .pc .main >p {
    line-height: 25px;
    padding: 0 5px;
  }
}

@media (max-width: 767px) {
  .pc {
    display: none;
  }
  .mob {
    display: block;
  }
  .home_ {
    .giac-container {
      .main {
        padding: 0 20px;
        > h1,
        h1.head_title,
        .source_home .title {
          height: 20px;
          line-height: 20px;
          font-size: 20px;
        }
        > p,
        p.head_title,
        .source_home .desc {
          margin-top: 8px;
          min-height: 13px;
          line-height: 16px;
          font-size: 13px;
        }
      }
    }
    .banner {
      height: auto;
      min-height: 265px;
      padding: 30px 0 60px 0;
      // padding: 40px 0 60px 0;
      // padding: 50px 0 60px 0;
      background-image: url("../assets/img/giac2022_banner_mob.png");
      .main {
        img {
          width: auto;
          height: 25px;
        }
        > h1 {
          min-height: 27px;
          line-height: 27px;
          font-size: 27px;
          margin-top: 14px;
          p {
            // 16px
            height: 12px;
            min-height: 0;
            line-height: 12px;
            font-size: 12px;
            font-weight: 300;
            margin-top: 5px;
            letter-spacing: 0;
          }
        }
        > p {
          min-height: 0;
          margin-top: 13px;
          // margin-top: 15px;
          span {
            // 46px 28px 18px
            height: 23px;
            line-height: 23px;
            font-size: 14px;
            padding-right: 9px;
          }
        }
        div.time {
          margin-top: 12px;
          // margin-top: 20px;
          display: inline-block;
          p {
            // 17px
            min-height: 15px;
            line-height: 15px;
            font-size: 14px;
            margin-right: 0;
            margin-bottom: 8px;
            img {
              // 21px
              height: 17px;
              margin-right: 0;
              // margin-right: 5px;
            }
          }
          p:nth-of-type(1) {
            margin-right: 10px;
          }
        }
        div.btns {
          // margin-top: 15px;
          margin-top: 10px;
          p {
            width: 140px;
            margin-right: 10px;
            margin-bottom: 0;
            line-height: 25px;
            font-size: 13px;
            border-radius: 6px;
          }
        }
      }
    }
    .top {
      // top: -41px;
      top: -38px;
      padding-bottom: 10px;
      position: relative;
      .main {
        padding: 0;
        .desc {
          // padding: 15px 20px;
          padding: 25px 20px;
          background-image: url("../assets/img/home_meeting_desc_bg.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 117.5% 117.5%;
          background-color: transparent;
          box-shadow: none;
          .le {
            display: none;
          }
          .ri {
            width: 100%;
            border: none;
            background-color: transparent;
            .titem:not(:nth-of-type(1)) {
              border-left: 1px solid #000000;
            }
            .titem {
              width: 33.33%;
              height: 44px; // 64px
              padding: 0 5px;
              h1::after {
                display: none;
              }
              h1 {
                width: 100%;
                margin-top: -10px;
                height: 26px;
                line-height: 26px;
                font-size: 26px;
                font-weight: bold;
                color: #2630b2;
                text-align: center;
              }
              p {
                width: 100%;
                margin-top: 5px;
                line-height: 16px;
                font-size: 12px;
                text-align: center;
              }
            }
          }
        }
        > p.mob {
          padding: 0 20px;
          margin-top: 20px;
          line-height: 20px;
          font-size: 14px;
          font-family: Alibaba PuHuiTi Regular;
          font-weight: 400;
          text-align: left;
          text-indent: 2em;
          span {
            font-family: Alibaba PuHuiTi Medium;
            font-weight: bold;
            color: #2630b2;
          }
        }
      }
      .hshadow {
        height: 110px;
        opacity: 0.4;
        background: linear-gradient(0deg, #e1e5f2 1%, #ffffff 60%);
      }
    }
    .theme-setting {
      .giac-container:nth-of-type(1) {
        .main {
          margin-top: 0;
        }
      }
      .giac-container.mob {
        width: 100%;
        margin-top: 15px;
        .theme {
          width: 100%;
          .theme_head.act {
            color: #2630b2;
          }
          .theme_head {
            width: 100%;
            padding: 17px 20px;
            padding-right: 32px;
            line-height: 14px;
            font-size: 14px;
            font-family: PingFang SC Medium;
            font-weight: 500;
            color: #999999;
            background: #f7f9fe;
            border: 1px solid #ffffff;
            position: relative;
            cursor: pointer;
            span {
              width: 9px;
              height: 9px;
              top: 17px;
              right: 20px;
              position: absolute;
              display: inline-block;
              border-top: 2px solid #2630b2;
              border-left: 2px solid #2630b2;
              transform: rotate(-135deg);
            }
            span.open,
            span.close {
              opacity: 1;
            }
            span.open {
              top: 21px;
              transform: rotate(45deg);
            }
            span.close {
              border-color: #999999;
            }
          }
          .theme_detail {
            width: 100%;
            >div {
              width: 100%;
            }
            .theme_title {
              width: 100%;
              padding: 0 20px;
              line-height: 40px;
              font-size: 14px;
              font-family: PingFang SC Medium;
              font-weight: 500;
              color: #FFFFFF;
              text-align: center;
              background: #2630B2;
              border: 1px solid #FFFFFF;
            }
            .theme_course {
              width: 100%;
              padding: 20px;
              border: 1px solid #FFFFFF;
              background: linear-gradient(165deg, #F6F8FC 0%, #FFFFFF 100%);
              h3 {
                margin-bottom: 0;
                line-height: 20px;
                font-size: 14px;
                font-family: PingFang SC Medium;
                font-weight: 500;
                color: #3B44B9;
              }
            }
            .theme_course p, .no_course {
              margin-bottom: 0;
              margin-top: 10px;
              line-height: 13px;
              font-size: 13px;
              font-family: PingFang SC;
              // font-family: PingFang SC Regular;
              font-weight: 500;
              color: #999999;
            }
            .no_course {
              text-align: center;
              padding-bottom: 20px;
            }
          }
        }
      }
    }
    .cases {
      .main {
        margin-top: 30px;
        padding-bottom: 35px;
        >p {
          margin-bottom: 25px;
        }
        .case-wrapper {
          padding: 0 15px;
          padding-top: 10px;
          .all-case{
            width: 100%;
            max-width: 300px;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            background: #2630B2;
          }
        }
      }
    }
    .comment-content {
      min-height: 300px;
      .main {
        padding-top: 40px;
        >h1 {
          margin-bottom: 25px;
        }
      }
    }
    .conferee {
      background-color: #FFFFFF;
      .main {
        margin-top: 35px;
        padding: 0 15px;
        >p {
          padding-bottom: 10px;
        }
      }
    }
    .sponsor {
      .main{
        padding: 0 15px;
      }
    }
  }
}
</style>